import Beep from '../../media/BeepTransparent.svg'
import BeepConnector from '../../components/BeepConnector'
import axios from "axios";
import endpoints from "../../endpoints.json";

export default function GetKeyBeep(props) {

    const saveBeepCode = (code) => {
        axios.get(endpoints.api + `setbeepcode/?key_id=${props.keyData.keyId}&beep_code=${code}`)
    }

    return (
        <div>
            <h2 className={'text-2xl mb-5'}>Подключите Beep</h2>
            <p>Beep - наша разработка для доставки уведомлений пользователям.</p><br/>

            <p>По сути, это обычный телеграм бот, который будет присылать вам от нас уведомления.</p><br/>

            <BeepConnector projectId={'fd0f90fe-9413-4287-a24c-31890e641fe7'} afterSuccess={() => props.moveStage()} output={(code) => saveBeepCode(code)}/>
        </div>
    )
}